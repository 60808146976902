import { CheckIcon } from '@heroicons/react/20/solid';
import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import { FieldValues, FieldPath, UseControllerProps, Controller } from 'react-hook-form';
import useEvent from 'src/hooks/useEvent';
import { FieldProps } from 'src/types/forms';
import { twMerge } from 'tailwind-merge';
import FieldError from '../FieldError';
import FieldLabel from '../FieldLabel';

export type CheckboxBaseProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> & {
  labelClassName?: string;
  labelTextClassName?: string;
  hideErrorMessage?: boolean;
  onChange: (value: boolean | null) => void;
  extraOnChange?: (value: boolean | null) => void;
  label?: ReactNode;
  error?: ReactNode;
  asterisk?: boolean;
};

export type CheckboxProps = Omit<FieldProps<boolean | null | undefined>, 'onChange'> & CheckboxBaseProps;

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {
    value,
    checked,
    labelClassName,
    className,
    labelTextClassName,
    label,
    error,
    onChange,
    extraOnChange,
    asterisk,
    ...rest
  } = props;

  const handleChange = useEvent((e: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(e.target.checked);
    extraOnChange?.(e.target.checked);
  });

  return (
    <div className={twMerge('flex flex-col', className)}>
      <label className='flex gap-x-3 items-center'>
        <div className={twMerge('w-4 h-4 rounded-sm border border-neutral-500', value && 'border-primary bg-primary')}>
          {value && <CheckIcon className='text-white' />}
        </div>
        <input
          type='checkbox'
          className={'hidden'}
          id={rest.name}
          {...rest}
          onChange={handleChange}
          checked={value ?? checked}
          ref={ref}
        />
        {label && (
          <FieldLabel asterisk={asterisk} className={labelClassName} textClassName={labelTextClassName}>
            {label}
          </FieldLabel>
        )}
      </label>
      {error && <FieldError>{error}</FieldError>}
    </div>
  );
});

type CheckboxControlledProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<CheckboxProps, 'value' | 'onChange' | 'name' | 'error'> & {
  control: UseControllerProps<TFieldValues, TName>['control'];
  name: TName;
};

export function CheckboxControlled<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ control, name, ...checkboxProps }: CheckboxControlledProps<TFieldValues, TName>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Checkbox {...checkboxProps} {...field} name={name} error={error?.message} />
      )}
    />
  );
}

export default Checkbox;
