import { parseISO, isSameDay, isAfter, isBefore, startOfDay, addDays } from 'date-fns';
import { useMemo } from 'react';
import { OrderListItemResponse } from 'src/api';
import useAllTechniciansWeekOrders from 'src/api/hooks/queries/useAllTechniciansWeekOrders';

export type CurrentWeekOrderGroups = {
  before: OrderListItemResponse[];
  today: OrderListItemResponse[];
  tomorrow: OrderListItemResponse[];
  afterTomorrow: OrderListItemResponse[];
};

type UseGrouppedCurrentWeekOrdersReturn = {
  data: CurrentWeekOrderGroups;
  isLoading: boolean;
};

const useGrouppedCurrentWeekOrders = (dayOfTheWeek: Date): UseGrouppedCurrentWeekOrdersReturn => {
  const { data: orders, isLoading } = useAllTechniciansWeekOrders(dayOfTheWeek);

  const grouppedOrders = useMemo<CurrentWeekOrderGroups>(() => {
    if (!orders) {
      return {
        before: [],
        today: [],
        tomorrow: [],
        afterTomorrow: [],
      };
    }

    const todayDate = new Date();
    const tomorrowDate = addDays(new Date(), 1);
    const ordersWithDates = orders.filter((order) => !!order.datetime && !!order.endDatetime) as Array<
      Omit<OrderListItemResponse, 'datetime' | 'endDatetime'> & { datetime: string; endDatetime: string }
    >;

    const today = ordersWithDates.filter((order) => {
      const orderDate = parseISO(order.datetime);
      return isSameDay(orderDate, todayDate);
    });
    const tomorrow = ordersWithDates.filter((order) => {
      const orderDate = parseISO(order.datetime);
      return isSameDay(orderDate, tomorrowDate);
    });
    const afterTomorrow = ordersWithDates.filter((order) => {
      const orderDate = parseISO(order.datetime);
      return isAfter(startOfDay(orderDate), startOfDay(tomorrowDate));
    });
    const before = ordersWithDates.filter((order) => {
      const orderDate = parseISO(order.datetime);
      return isBefore(startOfDay(orderDate), startOfDay(todayDate));
    });

    return {
      before,
      today,
      tomorrow,
      afterTomorrow,
    };
  }, [orders]);

  return { data: grouppedOrders, isLoading };
};

export default useGrouppedCurrentWeekOrders;
