import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export type FieldLabelProps = {
  className?: string;
  textClassName?: string;
  children: ReactNode;
  asterisk?: boolean;
};

const FieldLabel: FC<FieldLabelProps> = ({ className, textClassName, asterisk, children }) => {
  return (
    <div className={twMerge('flex w-full', className)}>
      <span className={twMerge('font-semibold w-full flex', textClassName)}>
        {children}
        {asterisk && <span className='font-semibold'>*</span>}
      </span>
    </div>
  );
};

export default FieldLabel;
