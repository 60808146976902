import { FC, useMemo, useState } from 'react';
import { format, subMonths, addMonths, startOfWeek, addDays, isSameDay, isSameMonth } from 'date-fns';
import useUserFnsLocale from 'src/api/hooks/queries/useUserFnsLocale';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { getCalendarDates } from './helpers';
import { chunks } from 'src/utils/chunks';
import { twMerge } from 'tailwind-merge';

export type WeekPickerProps = {
  className?: string;
  defaultCurrentMonthStart?: Date;
  defaultSelected?: { monthStart: Date; weekRow: number; weekStart: Date; weekEnd: Date };
  onWeekSelect?: (result: { monthStart: Date; weekRow: number; weekStart: Date; weekEnd: Date }) => void;
};

const WeekPicker: FC<WeekPickerProps> = ({ className, defaultCurrentMonthStart, defaultSelected, onWeekSelect }) => {
  const { data: locale } = useUserFnsLocale();
  const [currentMonthStart, setCurrentMonthStart] = useState(() => defaultCurrentMonthStart ?? new Date());
  const [selected, setSelected] = useState<{ monthStart: Date; weekRow: number } | undefined>(defaultSelected);

  const weekDays = useMemo(
    () =>
      Array.from({ length: 7 })
        .map((_, index) => format(addDays(startOfWeek(new Date(), { weekStartsOn: 1 }), index), 'EE', { locale }))
        .map((day) => day.charAt(0).toUpperCase()),
    [locale],
  );

  const dates = useMemo(() => getCalendarDates(currentMonthStart), [currentMonthStart]);
  const weekRows = useMemo(() => chunks(dates, 7), [dates]);

  const handleNextMonth = (): void => setCurrentMonthStart((prev) => addMonths(prev, 1));
  const handlePrevMonth = (): void => setCurrentMonthStart((prev) => subMonths(prev, 1));

  const handleSelectWeek = (monthStart: Date, weekRow: number): void => {
    setSelected({ monthStart, weekRow });
    onWeekSelect?.({
      monthStart,
      weekRow,
      weekStart: weekRows[weekRow][0],
      weekEnd: weekRows[weekRow].at(-1) as Date,
    });
  };

  return (
    <div className={twMerge('w-max', className)}>
      <div className='flex justify-between items-center mb-2'>
        <span className='capitalize text-primaryFocus text-lg'>
          {format(currentMonthStart, 'LLLL', { locale })} {currentMonthStart.getFullYear()}
        </span>
        <div className='text-primaryFocus'>
          <button onClick={handlePrevMonth}>
            <ChevronLeftIcon className='w-8 h-8' />
          </button>
          <button onClick={handleNextMonth}>
            <ChevronRightIcon className='w-8 h-8' />
          </button>
        </div>
      </div>
      <div className='grid grid-cols-7 mb-2'>
        {weekDays.map((day, index) => (
          <span key={index} className='text-center text-xs font-light'>
            {day}
          </span>
        ))}
      </div>
      <div className='flex flex-col gap-y-1'>
        {weekRows.map((week, index) => {
          const isSelected =
            selected && isSameDay(selected.monthStart, currentMonthStart) && selected.weekRow === index;

          return (
            <div
              key={index}
              onClick={() => handleSelectWeek(currentMonthStart, index)}
              className={twMerge(
                'cursor-pointer grid grid-cols-7 border border-transparent rounded',
                isSelected && 'border-primary',
                !isSelected && 'hover:border-y-gray-400 hover:border-dashed',
              )}
            >
              {week.map((date) => (
                <span
                  key={date.getTime()}
                  className={twMerge(
                    'flex justify-center items-center h-8 w-8 text-center',
                    isSelected && 'first:bg-primaryFocus first:text-white last:bg-primaryFocus last:text-white',
                    !isSameMonth(date, currentMonthStart) && 'text-gray-400',
                  )}
                >
                  {date.getDate()}
                </span>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WeekPicker;
