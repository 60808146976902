/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import { AuthService } from './services/AuthService';
import { BranchService } from './services/BranchService';
import { ContactService } from './services/ContactService';
import { DashboardService } from './services/DashboardService';
import { DeliveryRatesService } from './services/DeliveryRatesService';
import { DeliveryTariffsService } from './services/DeliveryTariffsService';
import { EmployeeService } from './services/EmployeeService';
import { ExternalSyncService } from './services/ExternalSyncService';
import { ExternalTenantManagmentService } from './services/ExternalTenantManagmentService';
import { FileService } from './services/FileService';
import { HeadquartersService } from './services/HeadquartersService';
import { LicencesService } from './services/LicencesService';
import { MaterialService } from './services/MaterialService';
import { NotificationSettingsService } from './services/NotificationSettingsService';
import { OrdersService } from './services/OrdersService';
import { OrderTypesService } from './services/OrderTypesService';
import { PaymentMethodService } from './services/PaymentMethodService';
import { ProductService } from './services/ProductService';
import { ProductTemplateService } from './services/ProductTemplateService';
import { RoutineServicesService } from './services/RoutineServicesService';
import { SavedOrderFormsService } from './services/SavedOrderFormsService';
import { StatusService } from './services/StatusService';
import { StockOrdersService } from './services/StockOrdersService';
import { TechnicianOrderService } from './services/TechnicianOrderService';
import { TenantService } from './services/TenantService';
import { TenantConfigsService } from './services/TenantConfigsService';
import { TranslationPacksService } from './services/TranslationPacksService';
import { UserService } from './services/UserService';
import { UserProfileService } from './services/UserProfileService';
import { VatTariffsService } from './services/VatTariffsService';
import { WorkTariffsService } from './services/WorkTariffsService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class AppClient {
  public readonly auth: AuthService;
  public readonly branch: BranchService;
  public readonly contact: ContactService;
  public readonly dashboard: DashboardService;
  public readonly deliveryRates: DeliveryRatesService;
  public readonly deliveryTariffs: DeliveryTariffsService;
  public readonly employee: EmployeeService;
  public readonly externalSync: ExternalSyncService;
  public readonly externalTenantManagment: ExternalTenantManagmentService;
  public readonly file: FileService;
  public readonly headquarters: HeadquartersService;
  public readonly licences: LicencesService;
  public readonly material: MaterialService;
  public readonly notificationSettings: NotificationSettingsService;
  public readonly orders: OrdersService;
  public readonly orderTypes: OrderTypesService;
  public readonly paymentMethod: PaymentMethodService;
  public readonly product: ProductService;
  public readonly productTemplate: ProductTemplateService;
  public readonly routineServices: RoutineServicesService;
  public readonly savedOrderForms: SavedOrderFormsService;
  public readonly status: StatusService;
  public readonly stockOrders: StockOrdersService;
  public readonly technicianOrder: TechnicianOrderService;
  public readonly tenant: TenantService;
  public readonly tenantConfigs: TenantConfigsService;
  public readonly translationPacks: TranslationPacksService;
  public readonly user: UserService;
  public readonly userProfile: UserProfileService;
  public readonly vatTariffs: VatTariffsService;
  public readonly workTariffs: WorkTariffsService;
  public readonly request: BaseHttpRequest;
  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '1',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });
    this.auth = new AuthService(this.request);
    this.branch = new BranchService(this.request);
    this.contact = new ContactService(this.request);
    this.dashboard = new DashboardService(this.request);
    this.deliveryRates = new DeliveryRatesService(this.request);
    this.deliveryTariffs = new DeliveryTariffsService(this.request);
    this.employee = new EmployeeService(this.request);
    this.externalSync = new ExternalSyncService(this.request);
    this.externalTenantManagment = new ExternalTenantManagmentService(this.request);
    this.file = new FileService(this.request);
    this.headquarters = new HeadquartersService(this.request);
    this.licences = new LicencesService(this.request);
    this.material = new MaterialService(this.request);
    this.notificationSettings = new NotificationSettingsService(this.request);
    this.orders = new OrdersService(this.request);
    this.orderTypes = new OrderTypesService(this.request);
    this.paymentMethod = new PaymentMethodService(this.request);
    this.product = new ProductService(this.request);
    this.productTemplate = new ProductTemplateService(this.request);
    this.routineServices = new RoutineServicesService(this.request);
    this.savedOrderForms = new SavedOrderFormsService(this.request);
    this.status = new StatusService(this.request);
    this.stockOrders = new StockOrdersService(this.request);
    this.technicianOrder = new TechnicianOrderService(this.request);
    this.tenant = new TenantService(this.request);
    this.tenantConfigs = new TenantConfigsService(this.request);
    this.translationPacks = new TranslationPacksService(this.request);
    this.user = new UserService(this.request);
    this.userProfile = new UserProfileService(this.request);
    this.vatTariffs = new VatTariffsService(this.request);
    this.workTariffs = new WorkTariffsService(this.request);
  }
}
