import { FC, useState } from 'react';
import Popup from 'src/components/Popup';
import {
  VatTariffResponse,
  HeadquartersResponse,
  OrderTypeListItemRepsonse,
  TenantConfigDto,
  OrderResponse,
} from 'src/api';
import clsx from 'clsx';
import AllTechniciansCalendar from 'src/pages/CalendarPage/components/AllTechniciansCalendar';
import MobileServicePopupTitle, { MobileServicePopupMode } from '../MobileServicePopupTitle/MobileServicePopupTitle';
import UpdateServiceForm from '../UpdateServiceForm';
import { FormattedMessage } from 'react-intl';
import { parseISO } from 'date-fns';
import { GridEvent } from 'src/components/EventsGrid/types';

export type MobileUpdateServicePopupProps = {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  vatTariffs: VatTariffResponse[];
  headquarters: HeadquartersResponse[];
  orderTypes: OrderTypeListItemRepsonse[];
  order: OrderResponse;
  config?: TenantConfigDto;
};

const MobileUpdateServicePopup: FC<MobileUpdateServicePopupProps> = ({
  open,
  order,
  onClose,
  isLoading,
  config,
  orderTypes,
  vatTariffs,
  headquarters,
}) => {
  const [mode, setMode] = useState<MobileServicePopupMode>(MobileServicePopupMode.form);
  const [potentialEvents, setPotentialEvents] = useState<GridEvent[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(() => {
    if (!order.datetime) return undefined;
    try {
      return parseISO(order.datetime);
    } catch (error) {
      console.error('Failed to parse order datetime', error);
      return undefined;
    }
  });

  const handleClose = (): void => {
    setSelectedDate(undefined);
    setPotentialEvents([]);
    setMode(MobileServicePopupMode.form);
    onClose();
  };

  return (
    <Popup
      stickTop
      disableCloseOnEscape
      disableCloseOnClickOutside
      replaceTitleContainer
      title={
        <MobileServicePopupTitle
          title={<FormattedMessage id='app.order_detail_page.update_order.popup_title' />}
          mode={mode}
          onModeChange={setMode}
        />
      }
      open={open && !isLoading}
      onClose={handleClose}
    >
      <UpdateServiceForm
        className={clsx(mode === MobileServicePopupMode.calendar && 'hidden')}
        config={config}
        order={order}
        orderTypes={orderTypes}
        vatTariffs={vatTariffs}
        headquarters={headquarters}
        onDateChange={setSelectedDate}
        onPotentialEventsChange={setPotentialEvents}
      />
      <AllTechniciansCalendar
        className={clsx(mode === MobileServicePopupMode.form && 'hidden')}
        calendarOptions={{
          defaultFocusedDate: selectedDate,
        }}
        potentialEvents={potentialEvents}
        disableTechnicianPreviewOnClick
        disableEventPreviewOnClick
        disableDnd
        disableGridClick
      />
    </Popup>
  );
};

export default MobileUpdateServicePopup;
