import { Navigate, Route, Routes } from 'react-router-dom';
import { UserResponse } from 'src/api';
import useLicenses from 'src/api/hooks/queries/useLicenses';
import useUserProfile from 'src/api/hooks/queries/useUserProfile';
import DayTab from 'src/components/TechnicianCalendar/tabs/DayTab';
import MonthTab from 'src/components/TechnicianCalendar/tabs/MonthTab';
import WeekTab from 'src/components/TechnicianCalendar/tabs/WeekTab';
import Loader from 'src/components/utils/Loader';
import useGetHasLicense from 'src/hooks/useGetHasLicense';
import useGetIsRoleAllowed from 'src/hooks/useGetIsRoleAllowed';
import BranchesPage from 'src/pages/BranchesPage';
import CalendarPage from 'src/pages/CalendarPage';
import AllTechniciansTab from 'src/pages/CalendarPage/components/AllTechniciansTab';
import SingleTechnicianCalendar from 'src/pages/CalendarPage/components/SingleTechnicianCalendar';
import SingleTechnicianTab from 'src/pages/CalendarPage/components/SingleTechnicianTab';
import ContactDetailPage from 'src/pages/ContactDetailPage';
import ContactPage from 'src/pages/ContactPage';
import CustomTranslationsWizzardPage from 'src/pages/CustomTranslationsWizzardPage';
import HomePage from 'src/pages/HomePage/HomePage';
import MaterialPage from 'src/pages/MaterialPage';
import MaterialDetailPage from 'src/pages/MaterialPage/MaterialDetailPage';
import NotFoundPage from 'src/pages/NotFoundPage';
import OrderTypeDetailPage from 'src/pages/OrderTypeDetailPage';
import OrderTypeTranslationDetailPage from 'src/pages/OrderTypeTranslationDetailPage/OrderTypeTranslationDetailPage';
import OrderTypesPage from 'src/pages/OrderTypesPage';
import OrdersPage from 'src/pages/OrdersPage';
import ProductDetailPage from 'src/pages/ProductDetailPage';
import ProductDocumentationPage from 'src/pages/ProductDocumentationPage';
import ProductHistoryPage from 'src/pages/ProductHistoryPage';
import ProductsPage from 'src/pages/ProductsPage';
import ProfileSettingPage from 'src/pages/ProfileSettingPage/ProfileSettingPage';
import ServerErrorPage from 'src/pages/ServerErrorPage';
import ServicePreviewPage from 'src/pages/ServicePreviewPage';
import SettingsPage from 'src/pages/SettingsPage';
import SystemAdministrationFeaturesPage from 'src/pages/SystemAdministrationFeaturesPage';
import TechnicianAccountPage from 'src/pages/TechnicianAccountPage';
import TechnicianAccountCalendar from 'src/pages/TechnicianAccountPage/components/TechnicianAccountCalendar';
import TenantDetailPage from 'src/pages/TenantDetailPage';
import TenantsPage from 'src/pages/TenantsPage';
import TranslationsPage from 'src/pages/TranslationsPage';
import TranslationsWizzardPage from 'src/pages/TranslationsWizzardPage';

export const AppRouter: React.FC = () => {
  const { isLoading: isLoadingLicenses } = useLicenses();
  const { isLoading: isLoadingUserProfile } = useUserProfile();

  const getHasLicense = useGetHasLicense();
  const getIsRoleAllowed = useGetIsRoleAllowed();

  if (isLoadingLicenses || isLoadingUserProfile) return <Loader />;

  return (
    <>
      <Routes>
        <Route path='/' element={getHasLicense() ? <HomePage /> : <Navigate to='/login' />} />
        <Route path='/order' element={<OrdersPage />} />
        <Route path='order/:orderId' element={<ServicePreviewPage />} />
        <Route path='/404' element={<NotFoundPage />} />
        <Route path='/500' element={<ServerErrorPage />} />

        {/* TODO: add auth */}
        <Route path='/technician-account' element={<TechnicianAccountPage />}>
          <Route path='' element={<TechnicianAccountCalendar />}>
            <Route path='month' element={<MonthTab />} />
            <Route path='week' element={<WeekTab />} />
            <Route path='day' element={<DayTab />} />
            <Route path='' element={<Navigate to='month' />} />
          </Route>
        </Route>
        <Route path='/calendar' element={<CalendarPage />}>
          <Route path='single/:technicianId' element={<SingleTechnicianTab />}>
            <Route path='' element={<SingleTechnicianCalendar />}>
              <Route path='month' element={<MonthTab />} />
              <Route path='week' element={<WeekTab />} />
              <Route path='day' element={<DayTab />} />
              <Route path='' element={<Navigate to='month' />} />
            </Route>
          </Route>
          <Route path='all' element={<AllTechniciansTab />} />
          <Route path='' element={<Navigate to='/calendar/all' />} />
        </Route>

        <Route path='/material/*' element={<MaterialPage />} />
        <Route path='/material/:materialId' element={<MaterialDetailPage />} />

        {getIsRoleAllowed([UserResponse.role.SYSTEM_ADMINISTRATOR]) && (
          <>
            <Route path='/tenant' element={<TenantsPage />} />
            <Route path='/tenant/:tenantId' element={<TenantDetailPage />} />
            <Route path='/sysadmin-features' element={<SystemAdministrationFeaturesPage />} />
            <Route path='/translations' element={<TranslationsPage />} />
            <Route path='/translations/:language/wizzard' element={<TranslationsWizzardPage />} />
            <Route path='/custom-translations/:language/wizzard' element={<CustomTranslationsWizzardPage />} />
            <Route path='/types-of-orders' element={<OrderTypesPage />} />
            <Route path='/types-of-orders/:orderTypeId' element={<OrderTypeDetailPage />} />
            <Route
              path='/types-of-orders/:orderTypeId/translations/:language'
              element={<OrderTypeTranslationDetailPage />}
            />
          </>
        )}

        <Route path='/contact/*' element={<ContactPage />} />
        <Route path='/contact-detail/:contactId/*' element={<ContactDetailPage />} />
        <Route path='/product' element={<ProductsPage />} />
        <Route path='/product/:productId' element={<ProductDetailPage />} />
        <Route path='/product/:productId/history' element={<ProductHistoryPage />} />
        <Route path='/product/:productId/documentation' element={<ProductDocumentationPage />} />
        <Route path='/branch/*' element={<BranchesPage />} />
        <Route path='/profile-settings' element={<ProfileSettingPage />} />
        <Route path='/settings' element={<SettingsPage />} />
        <Route path='/*' element={<NotFoundPage />} />
      </Routes>
    </>
  );
};
