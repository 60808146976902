import { useCallback } from 'react';
import { useMemo } from 'react';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { OrderResponse, SavedOrderFormResponse } from 'src/api';
import useFinishTechnicianOrder from 'src/api/hooks/mutations/technicianOrder/useFinishTechnicianOrder';
import WithLoader from 'src/components/utils/WithLoader';
import { createTechnicianOrderFormDefaultValues } from '../../helpers';
import FinishOrderForm from '../FinishOrderForm/FinishOrderForm';
import { FinishOrderFormValues, OrderFinishSchema, OrderFinishWithRealTimeSchema } from '../FinishOrderForm/schema';
import { sanitizeForm } from '../FinishOrderForm/helpers';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';

export type FinishOrderFormContainerProps = {
  className?: string;
  order: OrderResponse;
  savedForm?: SavedOrderFormResponse;
};

const FinishOrderFormContainer: FC<FinishOrderFormContainerProps> = ({ order, savedForm, className }) => {
  const intl = useIntl();
  const finishOrder = useFinishTechnicianOrder();

  const defaultValues = useMemo(() => {
    return savedForm
      ? sanitizeForm(savedForm.json as OrderFinishSchema)
      : createTechnicianOrderFormDefaultValues(order, intl);
  }, []);

  const schema = order?.orderType?.slConfig?.allowManualRealTime ? OrderFinishWithRealTimeSchema : OrderFinishSchema;
  const form = useForm<OrderFinishSchema>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues as unknown as OrderFinishSchema,
    reValidateMode: 'onChange',
  });

  const navigate = useNavigate();

  const handleSubmit: SubmitHandler<OrderFinishSchema> = useCallback(
    async (data) => {
      const modifiedData = {
        ...data,
        ...data.customerSignature,
        slConfigOutput: (data.slConfigOutput && JSON.stringify(data.slConfigOutput)) || null,
        products: data.products.map((product) => ({
          ...product,
          materials:
            product.materials?.map((m) => ({
              ...m,
            })) ?? [],
        })),
        serviceDuration: Number(data.serviceDuration),
        serviceMileage: Number(data.serviceMileage),
        serviceTotalPrice: order?.orderType?.slConfig?.allowManualPrices ? null : data.serviceTotalPrice,
      };

      finishOrder.mutate(modifiedData as unknown as FinishOrderFormValues, {
        onSuccess: () => {
          navigate(`/order/${order.id}`);
        },
      });
    },
    [finishOrder, order],
  );

  return (
    <WithLoader isLoading={finishOrder.isLoading}>
      <FormProvider {...form}>
        <FinishOrderForm order={order} onSubmit={handleSubmit} className={className} />
      </FormProvider>
    </WithLoader>
  );
};

FinishOrderFormContainer.displayName = 'FinishOrderFormContainer';

export default FinishOrderFormContainer;
